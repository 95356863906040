/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"
import { Box } from "theme-ui"
import ArticleGridBlock from "../components/ArticleGridBlock"
import { dummyData } from "../constants"
import Container from "../components/Container"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ArticlesList from "../components/ArticlesList"

const Posts = ({ data, location }) => {
  const [postsBundle, setPostsBundle] = useState(null)

  const { nodes } = data.allBlog

  const buildPostsArray = () => {
    nodes.concat(dummyData.nodes)

    const concatNodes = nodes.concat(dummyData.nodes)

    let postArr = [[], [], []]
    concatNodes.forEach(node => {
      if (postArr[0].length <= 4) {
        // We have available space IF > or = to 5. push it to [0]
        postArr[0].push(node)
      } else {
        // [0] is blocked, send it to [1]
        if (postArr[1].length <= 3) {
          postArr[1].push(node)
        } else {
          //  [1] is blocked, send the rest to [2]
          postArr[2].push(node)
        }
      }
    })
    setPostsBundle(postArr)
  }

  useEffect(() => {
    buildPostsArray()
  }, [])
  if (postsBundle !== null) {
    return (
      <Layout location={location} title={"All Article Posts"}>
        <Seo
          title={"Article Posts"}
          description="View all of our posts, old and new. Help your small business thrive!"
        ></Seo>
        <Container style={{ p: 3 }} width={1400}>
          <ArticleGridBlock data={postsBundle} />
          <Box sx={{ mt: 4 }}>
            <ArticlesList articles={nodes} />
          </Box>
        </Container>
      </Layout>
    )
  }
  return ""
}

export default Posts

export const postePageQuery = graphql`
  query {
    allBlog {
      nodes {
        articleType
        author
        body
        bodyHTML
        bodyPlainText
        createdAt
        description
        ctaLink {
          title
          url
        }
        image {
          image {
            cloudinaryId
            id
          }
        }
        media {
          cloudinaryId
          description
          name
        }
        name
        readingTime
        readingTimeText
        subtitle
        tags
        title
      }
    }
  }
`
