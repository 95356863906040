import React, { useState } from "react"
import { Text, Flex, Box, Image } from "@theme-ui/components"
import { Link } from "gatsby"

import { getCloudinaryImage, slugifyLower } from "../../helpers"
import AuthorBadge from "../AuthorBadge"

const AlternateImageCard = ({ article }) => {
  const [isHovered, setIsHovered] = useState(false)
  const { title, author, createdAt, media, description } = article
  return (
    <Link to={`/posts/${slugifyLower(title)}`}>
      <Flex
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        sx={{
          height: "100%",
          minHeight: [175, 175, 200],
          width: "100%",
          backgroundColor: "isWhite",
          cursor: "pointer",
          mb: [3, 3, 0],
        }}
      >
        <Box
          sx={{
            width: isHovered
              ? ["45%", "45%", "45%", "45%"]
              : ["25%", "25%", "25%", "35%"],
            transition: "all 1s",
          }}
        >
          <Image
            src={getCloudinaryImage(media[0].cloudinaryId)}
            alt={title}
            sx={{
              display: "block",
              width: "100%",
              height: "100%",
              transition: "all 1s",
              objectFit: "cover",
              maxHeight: [175, 175, 200],
            }}
          ></Image>
        </Box>
        <Box sx={{ padding: [2, 2, 3], flex: 1 }}>
          <Text
            sx={{
              fontSize: isHovered ? 1 : [2, 2, 2, 3],
              transition: "all .25s",
              fontWeight: [600, 600, "inherit"],
            }}
          >
            {title}
          </Text>
          <Box>
            <Text
              sx={{
                fontSize: 0,
                transition: "all .25s",
                lineHeight: [0, 0, 0, 1.75],
              }}
            >
              {description.substring(50)}
            </Text>
          </Box>

          <AuthorBadge
            author={author}
            createdAt={createdAt}
            small
            smaller
          ></AuthorBadge>
        </Box>
      </Flex>
    </Link>
  )
}

export default AlternateImageCard
