import React from "react"
import { Box, Text } from "theme-ui"
import { Link } from "gatsby"

import { CLOUDINARY_BASE } from "../../constants"
import AuthorBadge from "../AuthorBadge"
import { slugifyLower } from "../../helpers"

const FullImageCard = ({ article }) => {
  const { title, description, author, createdAt, media } = article
  const card = {
    backgroundImage: ` linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ), url(${CLOUDINARY_BASE}/${media[0].cloudinaryId})`,
    width: "100%",
    height: "100%",
    minHeight: 400,

    backgroundPosition: "center",
    display: "flex",
    alignItems: "flex-end",
  }

  return (
    <Link to={`/posts/${slugifyLower(title)}`}>
      <Box sx={card}>
        <Box sx={content}>
          <Text sx={titleStyle} as="p">
            {title}
          </Text>
          <Text as="p" sx={descStyle}>
            {description}
          </Text>
          <AuthorBadge
            small
            smaller
            author={author}
            createdAt={createdAt}
          ></AuthorBadge>
        </Box>
      </Box>
    </Link>
  )
}

export default FullImageCard

const content = {
  //   height: "50%",
  width: "100%",

  px: 3,
  pb: 3,

  p: {
    color: "white",
  },
}

const titleStyle = {
  fontSize: 4,
}

const descStyle = {
  fontSize: 0,
}
