import React from "react"
import { Card, Text } from "theme-ui"
import { Link } from "gatsby"

import AuthorBadge from "../AuthorBadge"
import { getCloudinaryImage, slugifyLower } from "../../helpers"

const BasicCard = ({ article, idx }) => {
  const { title, author, createdAt, media } = article

  const isImageStyleVariant = idx === 1 || idx === 2

  return (
    <Link to={`/posts/${slugifyLower(title)}`}>
      <Card
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: "isWhite",
          padding: 3,
          borderRadius: 3,
          backgroundImage: isImageStyleVariant
            ? `linear-gradient(
          rgba(0, 0, 0, 0.5), 
          rgba(0, 0, 0, 0.5)
        ), url("${getCloudinaryImage(media[0].cloudinaryId)}")`
            : "",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Text
          sx={{
            fontSize: [1, 1, 1, 3],
            color: isImageStyleVariant ? "white" : "inherit",
          }}
        >
          {title}
        </Text>
        <AuthorBadge
          author={author}
          createdAt={createdAt}
          small
          smaller
          whiteText={isImageStyleVariant}
        ></AuthorBadge>
      </Card>
    </Link>
  )
}

export default BasicCard
