import React from "react"
import { Grid, Box } from "theme-ui"
import FullImageCard from "./masonry-display/FullImageCard"
import BasicCard from "./masonry-display/BasicCard"
import AlternateImageCard from "./masonry-display/AlternateImageCard"

const ArticleGridBlock = ({ data }) => {
  const renderData = data.map((bundle, bundleIDX) => {
    if (bundleIDX === 0) {
      // Do the styles for bundle 1.
      return (
        <Grid sx={gridStyleA} key={`bundle-${bundleIDX}`}>
          {bundle.map((article, idx, articles) => {
            if (idx === 0) {
              return (
                <Box sx={left} key={article.title}>
                  <FullImageCard article={article} />
                </Box>
              )
            } else {
              return (
                <Grid sx={right} key={article.title}>
                  {articles
                    .filter((a, idx) => idx !== 0)
                    .map((art, i) => (
                      <Box key={art.title}>
                        <BasicCard article={art} idx={i} />
                      </Box>
                    ))}
                </Grid>
              )
            }
          })}
        </Grid>
      )
    }

    if (bundleIDX === 1) {
      return (
        <Grid sx={gridStyleB} key={`bundle-${bundleIDX}`}>
          {bundle.map((article, idx) => {
            return (
              <Box key={article.title}>
                <AlternateImageCard article={article} />
              </Box>
            )
          })}
        </Grid>
      )
    }
    return ""
  })
  return renderData
}

export default ArticleGridBlock

const gridStyleA = {
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto",
  gap: 4,
  gridTemplateAreas: `"left right"`,
  display: ["block", "block", "grid"],
}

const gridStyleB = {
  mt: 4,
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "auto",
  gap: 4,
  display: ["block", "block", "grid"],
}

const left = {
  gridArea: "left",
}

const right = {
  display: ["block", "block", "grid"],
  gridTemplateColumns: "1fr 1fr",
  gridTemplateRows: "1fr 1fr",
  gap: "3 3",
  gridTemplateAreas: `". ."
    ". ."`,
  gridArea: "right",
  mt: 3,
  "> div": {
    mb: 3,
  },
  //   display: ["block", "block", "grid"],
}
